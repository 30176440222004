<template>
	<div class="grid_table manage_events">
		<div class="item_row heading">
			<div class="cover_event"></div>
			<div class="title_event">Title</div>
			<div class="date">Date</div>
			<div class="pages">Views</div>
			<div class="interested">Interested</div>
			<div class="shared">Shared</div>
			<div class="price">Price</div>
			<div class="more"></div>
		</div>

		<template v-if="dataTable && dataTable.length">
			<div class="item_row data" v-for="item of dataTable" :key="'A' + item.id">
				<div class="cover_event">
					<img v-if="item.images[0]" :src="apiUrl.slice(0, -1) + `${item.images[0].contentUrl}`" alt="photo">
				</div>
				<div class="title_event">
					<router-link :to="{name: 'eventDetails', params: {id: item.id}}" class="name">{{ item.title }}</router-link>
				</div>
				<div class="date">{{item.dateStart | moment("MMM DD")}}</div>
				<div class="pages">-</div>
				<div class="interested">-</div>
				<div class="shared">-</div>
				<div class="price">-</div>
				<div class="more">
					<!--				<button type="button" class="like"></button>-->
					<v-menu
						transition="slide-y-transition"
						:close-on-content-click="false"
						:nudge-width="120"
						offset-y
						nudge-left="60"
						nudge-bottom="15"
						origin="top center"
						content-class="menu-pointer charcoal"
					>
						<template v-slot:activator="{ on, attrs }">
							<button type="button" v-ripple class="more_menu" v-bind="attrs" v-on="on"></button>
						</template>
						<v-list>
							<v-list-item v-ripple>
								<button type="button">item 1</button>
							</v-list-item>
							<v-list-item v-ripple>
								<button type="button">item 2</button>
							</v-list-item>
							<v-list-item v-ripple disabled>
								<button type="button">item 3</button>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</div>
			<pagination v-if="countEvents > 30"
				:count="countEvents"
				:currentPage="currentPage"
				:commit="'events/SET_EVENTS_CURRENT_PAGE'"
			></pagination>
		</template>

		<template v-if="loader">
			<v-skeleton-loader
				type="image"
				class="table_item_row"
				v-for="index in 10"
				:key="index"
			></v-skeleton-loader>
		</template>

		<template v-if="!loader && !dataTable.length">
			<nothingHereYet></nothingHereYet>
		</template>

	</div>
</template>

<script>
import {mapGetters} from "vuex";

import nothingHereYet from "@/components/small/nothingHereYet";
import pagination from "@/components/pagination";
export default {
	name: "eventsTable",
	props: ['dataTable'],
	components: {
		nothingHereYet,
		pagination
	},
	data() {
		return {
			apiUrl: process.env.VUE_APP_API_URL
		}
	},
	computed: {
		...mapGetters({
			loader: 'events/EVENTS_LOADING',
			countEvents: 'events/COUNT_EVENTS',
			currentPage: 'events/CURRENT_PAGE',
		})
	}
}
</script>

<style scoped>

</style>